import { currencyToNumber } from '@/@core/helpers/formatter';
import { useUiStateStore } from '@/@core/services/uiStateStore';
import { reactive, toRefs, unref } from '@vue/composition-api';
import { useMetaStore } from '../../services/store';

type MetaModelState = {
  headers: any[];
  isLoading: boolean;
  showFormDialog: boolean;
  showDeleteDialog: boolean;
  isFormValid: boolean;
  formData: any;
  search: string;
};

const state = reactive<MetaModelState>({
  headers: [
    {
      text: 'Nama Satuan/Meta',
      value: 'name'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      width: '1%'
    }
  ],
  isLoading: false,
  showFormDialog: false,
  showDeleteDialog: false,
  isFormValid: false,
  formData: null,
  search: ''
});

export default function useMetaModel() {
  const store = useMetaStore();

  const onGetManyMeta = async () => {
    state.isLoading = true;

    try {
      state.isLoading = true;
      await store.getManyMeta();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onCreateMeta = async (data: any) => {
    state.isLoading = true;

    try {
      await store.postMeta(data);
      useUiStateStore().createSnackbar({
        message: `Pengaturan berhasil ditambahkan.`
      });

      state.showFormDialog = false;
      await onGetManyMeta();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onUpdateMeta = async (data: any) => {
    state.isLoading = true;

    try {
      await store.putMeta(data);
      useUiStateStore().createSnackbar({
        message: `Pengaturan berhasil diubah.`
      });
      state.showFormDialog = false;
      await onGetManyMeta();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const createMeta = () => {
    state.formData = null;
    state.showFormDialog = true;
  };

  const editMeta = (meta: any) => {
    state.formData = { ...meta };
    state.showFormDialog = true;
  };

  const deleteMeta = (meta: any) => {
    state.formData = meta;
    state.showDeleteDialog = true;
  };

  const onDeleteMeta = async () => {
    state.isLoading = true;

    try {
      await store.deleteMeta(state.formData);
      const data: any = state.formData || {};
      useUiStateStore().createSnackbar({
        message: `Pengaturan berhasil dihapus.`
      });
      state.showDeleteDialog = false;
      state.formData = null;
      await onGetManyMeta();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onSubmitForm = (meta: any) => {
    const isEdit = (state.formData as any)?.id;
    const data = {...unref(meta)}
    
    data.microIndustryMax = currencyToNumber(data.microIndustryMax)
    data.smallIndustryMax = currencyToNumber(data.smallIndustryMax)
    data.mediumIndustryMax = currencyToNumber(data.mediumIndustryMax)

    if (isEdit) onUpdateMeta(data);
    else onCreateMeta(data);
  };

  return {
    ...toRefs(state),
    store,
    onGetManyMeta,
    onSubmitForm,
    onDeleteMeta,
    deleteMeta,
    editMeta,
    createMeta
  };
}

export { useMetaModel };
